<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>

    <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
    </v-toolbar>
    <ejs-treegrid  id="grid1" ref="grid" :dataSource='items' v-if='dataLoaded' childMapping='sub' :treeColumnIndex='0'
                  :height='560' :allowExcelExport='true' :excelHeaderQueryCellInfo='excelHeader'
                  :allowFiltering='true' :filterSettings="{type: 'Menu'}">
      <e-columns>
        <e-column field='summary_pos' :headerText="translate('plannings_plbs_summary_pos')" width=220>
        </e-column>
        <e-column field='position' :headerText="translate('plannings_plbs_position')" width=220>
        </e-column>
        <e-column textAlign='center' :headerText="translate('Periods')" :columns='periodsColumns'>
        </e-column>
      </e-columns>
    </ejs-treegrid>
    <br>
    <ejs-treegrid id="grid2" ref="gridEco"  :dataSource='resultItems' childMapping='sub'
                  :treeColumnIndex='0'  :allowExcelExport='true' :excelHeaderQueryCellInfo='excelHeader'>
      <e-columns>
        <e-column field='description' headerText='Description' width='300'></e-column>
        <e-column field='exta' headerText='' width='300'></e-column>
        <e-column textAlign='center' :columns='columnItems'></e-column>
      </e-columns>
    </ejs-treegrid>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import Breadcrumbs from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import {
  TreeGridPlugin, Page, Sort, Resize, CommandColumn, ExcelExport, Toolbar, Filter,
} from '@syncfusion/ej2-vue-treegrid';
import api from '../api/lbt-api';

const {
  getSummaryBSECOForPlanning,
} = api;

Vue.use(TreeGridPlugin);

export default {
  name: 'SummaryBsEco',
  components: {
    Breadcrumbs,
    Snackbar,
  },
  data() {
    return {
      notificationText: '',
      loader: false,
      dataLoaded: false,
      notificationType: null,
      items: [],
      expanded: [],
      periods: [],
      resultItems: [],
      columnItems: [],
      toolbar: ['ExcelExport', 'PdfExport', 'CsvExport'],
      periodsColumns: [],
      sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      searchOptions: { fields: ['leasing_type_id'], operator: 'contains', ignoreCase: true },
      pageSettings: { pageSizes: true, pageSize: 100 },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'Summary BS ECO',
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  provide: {
    treegrid: [Page, Sort, Resize, CommandColumn, ExcelExport, Toolbar, Filter],
  },
  async created() {
    this.headers = [

      {
        text: this.translate('plannings_plbs_summary_pos'),
        align: 'left',
        sortable: false,
        colspan: 10,
      },

      {
        text: this.translate('plannings_plbs_position'),
        align: 'left',
        sortable: false,
        width: 350,
      },
    ];
    this.loader = true;
    try {
      const planning = await getSummaryBSECOForPlanning(this.$route.params.planningId);
      this.periods = planning.periods;
      // eslint-disable-next-line arrow-body-style
      this.headers = planning.periods;
      this.items = planning.rows;
      this.prepareHeaders();
      this.updateResultItems();
      this.dataLoaded = true;
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  methods: {
    toggleExpandedItem(item) {
      // eslint-disable-next-line
      item.isExpanded = !item.isExpanded;
      this.expanded.push(item);
    },
    customiseCell(queryCellInfo) {
      const { cell } = queryCellInfo;
      if (cell.textContent === 'NaN') {
        cell.classList.add('error');
      }
    },
    excelDownloadClick() {
      const appendExcelExportProperties = {
        multipleExport: { type: 'AppendToSheet', blankRows: 5 },
        fileName: `Summary BS ECO- ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      const firstGridExport = this.$refs.grid.excelExport(appendExcelExportProperties, true);
      firstGridExport.then((fData) => {
        this.$refs.gridEco.excelExport(appendExcelExportProperties, false, fData);
      });
    },
    excelHeader(args) {
      const { cell } = args;
      if (cell.index > 2) {
        cell.value = this.headers[cell.value];
      }
    },
    lookupHeader(idx) {
      return this.periods[Number(idx)];
    },
    toolbarClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.excelExport();
    },
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.copy(true);
    },
    prepareHeaders() {
      const item = this.items[0];
      for (let j = 0; j < item.values.length; j += 1) {
        this.periodsColumns.push({
          field: `values.${j}`,
          headerText: j,
          headerValueAccessor: this.lookupHeader,
          width: 120,
          allowFiltering: false,
          textAlign: 'right',
          format: 'n2',
        });
      }
    },
    updateResultItems() {
      const row10 = this.items.length > 4 ? this.items[4].values : [];
      const row5 = this.items.length > 8 ? this.items[9].values : [];
      const differences = row10.map((value, index) => value - (row5[index] || 0));
      if (row10.length && row5.length) {
        // Prepare a single row with each index's difference
        this.resultItems = [{
          description: 'Aseets=Liabilities+Equity',
          ...differences.reduce((acc, diff, index) => ({ ...acc, [`diff${index}`]: diff }), {}),
        }];
      }
      this.columnItems = differences.map((_, index) => ({
        field: `diff${index}`,
        headerText: this.headers[Number(index)],
        width: 100,
        textAlign: 'Right',
        format: 'N2',
      }));
    },
  },
};
</script>


<style>
  @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

  .container {
    padding-bottom: 5rem !important;
  }

  .e-grid.e-default .e-gridheader th.e-firstcell {
    border-left: none;
  }

  .e-dlg-container.e-dlg-center-center,
  .e-dlg-container.e-dlg-center-center {
    display: none;
  }

  .e-grid .e-headercell.highlight-cell, .highlight-cell {
    background: #eeeeee;
  }

  .v-icon.v-icon.delete-action {
    font-size: 16px;
  }
</style>
