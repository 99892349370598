<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
      <v-col cols="12" sm="3"
             class="px-0">
        <v-text-field
          clearable
          v-model="search"
          append-icon="mdi-magnify"
          :label="translate('plannings_sales_search_items')"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary"
             @click.stop="addNewPlanningItem()">
        <v-icon class="mr-2">mdi-table-large-plus</v-icon>
        {{translate('plannings_new_capex_item')}}
      </v-btn>
    </v-toolbar>
    <ejs-grid :dataSource='items' v-if="dataLoaded" :allowSorting="true" :load='load' :height='600' :cellSave='cellSave'
              :editSettings='editSettings' :selectionSettings='selectionOptions' :pageSettings='pageSettings'
              :sortSettings='sortOptions' :filterSettings="{type: 'Menu'}" :searchSettings='searchOptions' :allowPaging='true' :allowFiltering='true'
              :commandClick='commandClick' gridLines='Horizontal' :queryCellInfo='customiseCell'
              :excelQueryCellInfo="excelExportPreprocessing" :allowExcelExport='true' :cellEdit="cellEdit"
              :allowResizing='true' :resizeStop="resizeStop" :excelHeaderQueryCellInfo='excelHeader'>
      <e-columns>
        <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true' :validationRules='requiredValidationRule' :width='columnWidths[0]'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false :width='columnWidths[1]'>
        </e-column>
        <e-column field='placement_period' :headerText="translate('plannings_capex_placement_period')" :valueAccessor='dropdownValueAccess' editType='dropdownedit'
                  :edit='placementPeriodParams' :validationRules='requiredValidationRule' :width='columnWidths[2]'>
        </e-column>
        <e-column field='asset_name' :headerText="translate('plannings_capex_asset_name')" :validationRules='requiredValidationRule' :width='columnWidths[3]' >
        </e-column>
        <e-column field='cost_center_name' :headerText="translate('plannings_capex_cost_center_name')" :validationRules='requiredValidationRule' :width='columnWidths[4]'>
        </e-column>
        <e-column field='cost_center_code' :headerText="translate('plannings_capex_cost_center_code')" :validationRules='requiredValidationRule' :width='columnWidths[5]'>
        </e-column>
        <e-column field='gl_account' :headerText="translate('plannings_capex_gl_account')" :validationRules='requiredValidationRule' :width='columnWidths[6]'>
        </e-column>
        <e-column field='finrep_bs_pl_id' :filter="filterFinrep" foreignKeyValue="name" :headerText="translate('plannings_capex_finrep_bs_pl_id')"  :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='finrepParams' :validationRules='requiredValidationRule' :width='columnWidths[7]'>
        </e-column>
        <e-column field='useful_life' :headerText="translate('plannings_capex_useful_life')"  editType='numericedit' :validationRules='requiredValidationRule' :width='columnWidths[8]'>
        </e-column>
        <e-column field='depreciation_rate' :headerText="translate('plannings_capex_depreciation_rate')" editType='numericedit' :validationRules='requiredValidationRule' :width='columnWidths[9]'>
        </e-column>
        <e-column field='capex_lcy' :headerText="translate('plannings_capex_lcy')" editType='numericedit' :validationRules='requiredValidationRule' :width='columnWidths[10]'>
        </e-column>
        <e-column field='capex_eur' :headerText="translate('plannings_capex_eur')" editType='numericedit' :validationRules='requiredValidationRule' :width='columnWidths[11]'>
        </e-column>
        <e-column field='capex_total' :headerText="translate('plannings_capex_total')" editType='numericedit' :allowEditing="false" :validationRules='requiredValidationRule' :width='columnWidths[12]'>
        </e-column>
        <e-column textAlign='center' :headerText="translate('table_actions')" :allowSorting="true" :commands='deleteActionCommand' :allowEditing="false" :width='columnWidths[13]'>
        </e-column>
        <e-column textAlign='center' :headerText="translate('plannings_capex_depreciation')" :allowSorting="true" :columns='depreciationColumns'>
        </e-column>
        <e-column textAlign='center' :headerText="translate('plannings_capex_book_value')" :allowSorting="true" :columns='bookValueColumns'>
        </e-column>
      </e-columns>
      <e-aggregates>
        <e-aggregate>
          <e-columns>
            <e-column type="Sum" field="capex_lcy" format="n2"></e-column>
            <e-column type="Sum" field="capex_eur" format="n2"></e-column>
            <e-column type="Sum" field="capex_total" format="n2"></e-column>
            <e-column type="Sum" v-for="col in depreciationColumnsComputed" :field="col.field" format="n2" v-bind:key="col.field" textAlign="left"></e-column>
            <e-column type="Sum" v-for="col in bookValueColumnsComputed" :field="col.field" format="n2" v-bind:key="col.field" textAlign="left"></e-column>
          </e-columns>
        </e-aggregate>
      </e-aggregates>
    </ejs-grid>
    <v-btn color="accent"
           fab
           fixed
           right
           bottom
           @click.stop="savePlanning()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <v-overlay :value="loader"
               :z-index="1000">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
import Vue from 'vue';
import {
  GridPlugin, Page, Sort, Edit, ForeignKey, Filter, Resize, Search, ExcelExport, CommandColumn, Aggregate,
} from '@syncfusion/ej2-vue-grids';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { createElement } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { mapGetters, mapState } from 'vuex';
import {
  map, flatMap, sumBy, toNumber,
} from 'lodash';
import Breadcrumbs from '../components/Breadcrumbs';
import ConfirmDialog from '../components/ConfirmDialog';
import api from '../api/lbt-api';
import store from '../store/index';
import { validatePlanningRows } from '../utilities/validatePlanningRows';
import { generatePlacementPeriodsCapex } from '../utilities/placementPeriod';
import { generateCapexDepreciationPeriods } from '../utilities/capexDepreciationPeriods';

// TODO: Refactor destructing
const {
  getPlanning,
  getCapexForPlanning,
  createCapexForPlanning,
  deleteCapexForPlanning,
  updateCapexForPlanning,
} = api;

let dropInstance;

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);

export default {
  name: 'SalesPlanningNew',
  components: {
    Breadcrumbs,
    ConfirmDialog,
  },
  async created() {
    this.loader = true;
    try {
      this.retrieveColumnWidths();
      await this.fetchPlanningCapexItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.planningInfo = planning;
      this.placementPeriodValues = generatePlacementPeriodsCapex(planning.year, planning.month);
      this.capexDepreciationPeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
      this.capexBookValuePeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
      for (let i = 0; i < this.placementPeriodValues.length; i += 1) {
        this.placementPeriodValues[i] = { id: this.placementPeriodValues[i], name: this.placementPeriodValues[i] };
      }
      this.finrepBsPlOptions = this.getAllOptions;
      this.prepareDepreciationGridHeaders();
      this.prepareBookValueGridHeaders();
      this.setDropdownDatasources();
      if (this.planningInfo.locked === 1) {
        this.editSettings.allowEditing = false;
        this.editSettings.allowAdding = false;
        this.editSettings.allowDeleting = false;
      }
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.dataLoaded = true;
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
    ...mapGetters('finrepBsPl', [
      'getAllOptions',
    ]),
    depreciationColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.depreciationColumns.length; i += 1) {
        if (this.depreciationColumns[i].showFooterSum) {
          data.push(this.depreciationColumns[i]);
        }
      }
      return data;
    },
    bookValueColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.bookValueColumns.length; i += 1) {
        if (this.bookValueColumns[i].showFooterSum) {
          data.push(this.bookValueColumns[i]);
        }
      }
      return data;
    },
    flattenedVolumePeriods() {
      return flatMap(this.financingVolumes, volume => volume.periods);
    },
    flattenedTicPeriods() {
      return flatMap(map(this.item.total_investment_costs), volume => volume.periods);
    },
    financingVolumeColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.financingVolumeColumns.length; i += 1) {
        if (this.financingVolumeColumns[i].showFooterSum) {
          data.push(this.financingVolumeColumns[i]);
        }
      }
      return data;
    },
    ticPeriodsColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.ticPeriodsColumns.length; i += 1) {
        if (this.ticPeriodsColumns[i].showFooterSum) {
          data.push(this.ticPeriodsColumns[i]);
        }
      }
      return data;
    },
    ponderForAveragesColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.ponderForAveragesColumns.length; i += 1) {
        if (this.ponderForAveragesColumns[i].showFooterSum) {
          data.push(this.ponderForAveragesColumns[i]);
        }
      }
      return data;
    },
  },
  watch: {
    search(searchText) {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      if (searchText !== null && searchText.length > 1) {
        grid.search(searchText);
      } else {
        grid.search();
      }
    },
  },
  data() {
    return {
      dataLoaded: false,
      search: '',
      notificationText: '',
      notificationType: '',
      loader: false,
      itemForDeletion: null,
      deleteDialog: false,
      deleteDialogDescription: '',
      capexDepreciationPeriods: [],
      capexBookValuePeriods: [],
      columnWidths: [0, 0, 120, 200, 120, 110, 80, 120, 70, 110, 50, 50, 50, 60],
      headers: [],
      bookValueColumns: [],
      depreciationColumns: [],
      items: null,
      plid: 0,
      pagination: {
        page: 1,
        size: 1000,
        total: 0,
        itemsPerPage: [100, 200, 300],
      },
      planningInfo: {},
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_plannings_capex',
          disabled: true,
        },
      ],
      json_fields: {
      },
      json_data: [
        {
          leasing_type_id: 255,
          product_group_id: 23,
        },
      ],
      excelData: [],
      leasingTypeValues: [],
      segmentValues: [],
      clientTypeValues: [],
      productGroupValues: [],
      channelValues: [],
      currencyValues: [],
      paymentAtOnceValues: [],
      typeValues: [],
      repaymentValues: [],
      interestRateTypeValue: [],
      referenceRateValues: [],
      accrualValues: [],
      financingVolumesValues: {},
      interestRateValues: {},
      ticVolumes: {},
      totalInterestRatesValues: {},
      ponderForAverageValues: {},
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch',
      },
      sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      searchOptions: { fields: ['product_name'], operator: 'contains', ignoreCase: true },
      requiredValidationRule: { required: true },
      pageSettings: { pageSizes: true, pageSize: 100 },
      placementPeriodParams: this.initDropdownParams(),
      finrepParams: this.initDropdownParams(),
      financingVolumeHeaders: [],
      percentageTypeParams: {
        params: {
          min: 0,
          max: 100,
          step: 0.01,
        },
      },
      filterFinrep: {
        ui: {
          create: (args) => {
            const flValInput = createElement('input', { className: 'flm-input' });
            args.target.appendChild(flValInput);
            dropInstance = new DropDownList({
              dataSource: this.finrepBsPlOptions,
              fields: { text: 'name', value: 'id' },
              placeholder: 'Select a value',
              popupHeight: '240px',
            });
            dropInstance.appendTo(flValInput);
          },
          write: (args) => {
            dropInstance.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstance.value);
          },
        },
      },
      highlightCellAttributes: { class: 'highlight-cell' },
      financingVolumeColumns: [],
      interestRatesColumns: [],
      ticPeriodsColumns: [],
      totalInterestRatesColumns: [],
      ponderForAveragesColumns: [],
      storagePrefix: 'capex_new',
      storageSeparator: '.-.',
      deleteActionCommand: [{ buttonOption: { cssClass: 'v-icon notranslate v-icon--link mdi mdi-delete theme--light delete-action' } }],
    };
  },
  provide: {
    grid: [Page, Sort, Edit, Resize, ForeignKey, Filter, Search, ExcelExport, CommandColumn, Aggregate, Resize],
  },
  methods: {
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      if (this.planningInfo.locked === 1) {
        this.editSettings.allowEditing = false;
        this.editSettings.allowAdding = false;
        this.editSettings.allowDeleting = false;
      } else {
      grid.element.addEventListener('mousedown', (e) => {
        if (e.target.classList.contains('e-rowcell')) {
          const index = Number(e.target.getAttribute('Index'));
          const colindex = Number(e.target.getAttribute('aria-colindex'));
          const { field } = grid.getColumns()[colindex];
          grid.editModule.editCell(index, field);
      }
      });
      }
    },
    retrieveColumnWidths() {
      for (let i = 0; i < this.columnWidths.length; i += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + i);
        if (width) {
          this.columnWidths[i] = width;
        }
      }
    },
    resizeStop(item) {
      let width = item.column.width.split('px');
      width = parseInt(width[0], 10);
      localStorage.setItem(this.storagePrefix + this.storageSeparator + item.column.index, width);
    },
    async fetchPlanningCapexItems() {
      const capex = await getCapexForPlanning(this.$route.params.planningId, this.pagination);
      this.items = capex.capex_entries;
      this.pagination.total = capex.pagination.total;
    },
    async savePlanning() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const [...changedRecords] = grid.getBatchChanges().changedRecords;
      if (addedRecords === undefined && changedRecords === undefined) {
        return;
      }

      const rows = grid.getRows();
      if (!rows || rows.length === 0) {
        return;
      }

      if (!this.validate()) {
        return;
      }

      this.loader = true;
      this.plid = this.$route.params.planningId;
      const salesCreateRequests = [];
      const salesUpdateRequests = [];
      try {
        if (addedRecords !== undefined) {
          for (let i = 0; i < addedRecords.length; i += 1) {
            salesCreateRequests.push(createCapexForPlanning(this.plid, addedRecords[i]));
          }
        }
        if (changedRecords !== undefined) {
          for (let j = 0; j < changedRecords.length; j += 1) {
            salesUpdateRequests.push(updateCapexForPlanning(this.plid, changedRecords[j], changedRecords[j].id));
          }
        }
        await Promise.all(salesCreateRequests);
        await Promise.all(salesUpdateRequests);

        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningCapexItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    deleteItem(item) {
      this.itemForDeletion = item;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.tempId) {
        let index = -1;
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === this.itemForDeletion.id) {
            index = i;
            break;
          }
        }

        if (index !== -1) {
          this.$delete(this.items, index);
          this.itemForDeletion = null;
        } else {
          const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
          const [...addedRecords] = grid.getBatchChanges().addedRecords;
          for (let i = 0; i < addedRecords.length; i += 1) {
            if (addedRecords[i].tempId === this.itemForDeletion.tempId) {
              grid.deleteRecord('id', addedRecords[i]);
              const rowIdx = grid.getRowIndexByPrimaryKey(addedRecords[i].id);
              const row = grid.getRowByIndex(rowIdx);
              grid.deleteRow(row);
              break;
            }
          }
        }
        this.deleteDialog = false;
        return;
      }
      this.loader = true;
      try {
        await deleteCapexForPlanning(this.$route.params.planningId, this.itemForDeletion.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        await this.fetchPlanningSalesItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
      } finally {
        this.loader = false;
      }
    },
    financingVolumeValueAccessor(column, item) {
      const colName = column.split('.');
      return `${item.financing_volumes[colName[1]].periods[colName[3]].value}%`;
    },
    interestRateValueAccessor(column, item) {
      const colName = column.split('.');
      return `${item.interest_rates[colName[1]].value}%`;
    },
    totalInterestRateValueAccessor(column, item) {
      const colName = column.split('.');
      return `${item.total_interest_rates[colName[1]].value}%`;
    },
    lookupFinancingVolumesPeriodHeader(idx) {
      return this.financingVolumeHeaders[Number(idx)];
    },
    lookupInterestRateHeader(idx) {
      return this.interestRatePeriods[Number(idx)].period;
    },
    lookupPonderForAveragesHeader(idx) {
      return this.ponderForAveragePeriods[Number(idx)].period;
    },
    initDropdownParams() {
      return {
        params: {
          dataSource: null,
          fields: { text: 'name', value: 'id' },
          query: new Query(),
        },
      };
    },
    setDropdownDatasources() {
      this.finrepParams.params.dataSource = this.finrepBsPlOptions;
      this.placementPeriodParams.params.dataSource = this.placementPeriodValues;
    },
    dropdownValueAccess(field, data) {
      return this.fetchCapexItemValues(field, data[field]);
    },
    prepareDepreciationGridHeaders() {
      const offset = 15;
      this.depreciationColumns = [];
      for (let j = 0; j < this.capexDepreciationPeriods.length; j += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + (offset + j));
        this.depreciationColumns.push({
          field: `depreciation.${j}.value`,
          headerValueAccessor: this.lookupDepreciationHeader,
          headerText: this.capexDepreciationPeriods[j],
          width: width != null ? width : 70,
          showFooterSum: true,
          editType: 'numericedit',
          // allowAdding: true,
          // allowEditing: true,
          allowSorting: false,
          allowFiltering: false,
          format: 'n2',
          textAlign: 'right',
        });
      }
    },
    lookupDepreciationHeader(idx) {
      return this.capexDepreciationPeriods[Number(idx)];
    },
    prepareBookValueGridHeaders() {
      const offset = 16 + this.depreciationColumns.length;
      this.bookValueColumns = [];
      for (let j = 0; j < this.capexBookValuePeriods.length; j += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + (offset + j));
        this.bookValueColumns.push({
          field: `book_value.${j}.value`,
          headerValueAccessor: this.lookupBookValueHeader,
          customAttributes: this.highlightCellAttributes,
          headerText: this.capexBookValuePeriods[j],
          width: width != null ? width : 70,
          showFooterSum: true,
          // allowAdding: true,
          // allowEditing: true,
          allowSorting: false,
          allowFiltering: false,
          format: 'n2',
          textAlign: 'right',
        });
      }
    },
    lookupBookValueHeader(idx) {
      return this.capexBookValuePeriods[Number(idx)];
    },
    cellEdit(args) {
      const isOldItem = !args.rowData.tempId && (args.columnName.startsWith('book_value.') || args.columnName.startsWith('depreciation.'));
      const argsC = args;
      if (isOldItem) {
        argsC.cancel = true;
      }
    },
    excelExportPreprocessing(item) {
      const tempItem = item;
      if (tempItem.value.toString().slice(-1) === '%') {
        const val = tempItem.value.toString();
        tempItem.value = parseFloat(val.substr(0, val.length - 1)) / 100;
      }
      return tempItem;
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        // exportType: 'CurrentPage',
        fileName: `CAPEX - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const col = args.gridCell.column;
      if (col.field !== undefined && col.field.includes('.')) {
        const { cell } = args;
        if (col.field.startsWith('financing')) {
          cell.value = this.lookupFinancingVolumesPeriodHeader(cell.value);

          const parts = col.field.split('.');
          if (parseFloat(parts[1]) >= 2 && parts[2] !== 'fy') {
            col.format = 'p2';
          }
        } else if (col.field.startsWith('total_investment')) {
          cell.value = this.lookupFinancingVolumesPeriodHeader(cell.value);
        } else if (col.field.startsWith('interest') || col.field.startsWith('total_interest')) {
          cell.value = this.lookupInterestRateHeader(cell.value);
          col.format = 'p2';
        } else if (col.field.startsWith('ponder')) {
          cell.value = this.lookupPonderForAveragesHeader(cell.value);
        }
      }
      if (col.field === 'down_payment' || col.field === 'dep_value' || col.field === 'guarantee' || col.field === 'residual_value' || col.field === 'up_front_fee' || col.field === 'ppmv') {
        col.format = 'p2';
      }
    },
    customiseCell(queryCellInfo) {
      const { cell } = queryCellInfo;
      if (cell.textContent === 'NaN') {
        cell.classList.add('error');
      }
    },
    commandClick(args) {
      this.deleteItem(args.rowData);
    },
    cellSave(args) {
      let volume;
      const colName = args.columnName.split('.');
      const { rowData } = args;
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const parentRow = args.cell.closest('tr');
      let fy = ''; // default message - if needed
      switch (colName[0]) {
        case 'financing_volumes':
          if (colName[2] === 'fy') break;

          volume = rowData.financing_volumes[colName[1]];
          for (let i = 0; i < grid.dataSource.length; i += 1) {
            if (grid.dataSource[i].id === rowData.id) {
              fy = sumBy(volume.periods, volumePeriod => toNumber(volumePeriod.value));
              if (colName[1] < 2) {
                grid.dataSource[i].financing_volumes[colName[1]].periods[colName[3]].value = args.value;
                grid.dataSource[i].financing_volumes[colName[1]].fy = fy;
                break;
              }
            }
          }


          for (let i = args.cell.cellIndex; i < parentRow.children.length; i += 1) {
            const colNameIn = grid.columnModel[i].field.split('.');
            if (colNameIn[0] === 'financing_volumes' && colNameIn[2] === 'fy') {
              if (colName[1] < 2) {
                parentRow.children[i].innerHTML = fy;
                break;
              }
            }
          }
          break;
        default:
          break;
      }

      if (args.value !== '') {
        const { cell } = args;
        cell.classList.add('e-updatedtd');
        cell.classList.remove('error');
      }
    },
    fetchCapexItemValues(key, value) {
      let fetchedValue = '';
      switch (key) {
        case 'placement_period':
          fetchedValue = this.getPlacementPeriodValue(value);
          break;
        case 'finrep_bs_pl_id':
          fetchedValue = this.getFinrepValue(value);
          break;
        default:
          break;
      }
      return fetchedValue;
    },
    percentageFieldAccessorValue(column, item) {
      return item[column] !== 'NaN' ? `${item[column]}%` : '';
    },
    getPlacementPeriodValue(value) {
      let fetchedValue = '';
      this.placementPeriodValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.name;
        }
      });
      return fetchedValue;
    },
    getFinrepValue(value) {
      let fetchedValue = '';
      this.finrepBsPlOptions.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.name;
        }
      });
      return fetchedValue;
    },
    updatePagination(page) {
      this.pagination.page = page;
      this.fetchPlanningSalesItems(this.pagination);
    },
    addNewPlanningItem() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const addedRecordsSize = addedRecords ? addedRecords.length : 0;
      const periods = generateCapexDepreciationPeriods(this.planningInfo.year, this.planningInfo.month - 1);
      for (let i = 0; i < periods.length; i += 1) {
        periods[i] = { period: 10 * i, value: 0 };
      }
      grid.addRecord({
        tempId: Math.random(),
        id: this.items.length + addedRecordsSize + 1,
        placement_period: 0,
        asset_name: '',
        cost_center_name: '',
        cost_center_code: '',
        gl_account: '',
        useful_life: '',
        finrep_bs_pl_id: 0,
        depreciation_rate: '',
        capex_lcy: 0,
        capex_eur: 0,
        capex_total: 0,
        depreciation: periods,
        book_value: periods,
      });
    },
    validate() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const [...changedRecords] = grid.getBatchChanges().changedRecords;
      if (validatePlanningRows(grid, addedRecords)
        && validatePlanningRows(grid, changedRecords)) {
        return true;
      }
      return false;
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
  },
  async beforeRouteEnter(from, to, next) {
    await store.dispatch('options/getOptions');
    await store.dispatch('finrepBsPl/getOptions');
    next();
  },
};
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

.container {
  padding-bottom: 5rem !important;
}

.e-grid.e-default .e-gridheader th.e-firstcell {
  border-left: none;
}

.e-dlg-container.e-dlg-center-center,
.e-dlg-container.e-dlg-center-center {
  display: none;
}

.e-grid .e-headercell.highlight-cell, .highlight-cell {
  background: #eeeeee;
}

.v-icon.v-icon.delete-action {
  font-size: 16px;
}

.e-summarycell {
  padding: 0 !important;
}

.e-filterbarcell {
  padding: 6px !important;
}
</style>
