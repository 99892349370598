<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>

    <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
    </v-toolbar>
    <h2>RISK RATIOS IFRS</h2>
     <ejs-grid id="ifrs" ref="grid"
      :dataSource='items'
      v-if="dataLoaded"
      :selectionSettings='selectionOptions' :pageSettings='pageSettings' :sortSettings='sortOptions' :searchSettings='searchOptions'
      :allowPaging='false' :withHeader='true' :allowFiltering='true' gridLines='Both' :load='load'
      :queryCellInfo='customiseCell' :allowExcelExport='true' :allowPdfExport='true' :allowGrouping='true'
      :allowTextWrap='true' :allowResizing='true' :frozenColumns='columns' :frozenRows="rows"  :excelHeaderQueryCellInfo='excelHeader'>
      <e-columns>
        <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false>
        </e-column>
        <e-column field='summary_pos' :headerText="translate('plannings_plbs_summary_pos')" width=220>
        </e-column>
        <e-column field='position' :headerText="translate('plannings_plbs_position')" width=220>
        </e-column>
        <e-column textAlign='center' :headerText="translate('Periods')" :columns='periodsColumns'>
        </e-column>
      </e-columns>
    </ejs-grid>
    <h2>RISK RATIOS EcoV</h2>
    <ejs-grid id="eco" ref="gridEco"
      :dataSource='itemsEco'
      v-if="dataLoadedEco"
      :selectionSettings='selectionOptions' :pageSettings='pageSettings' :sortSettings='sortOptions' :searchSettings='searchOptions'
      :allowPaging='false' :withHeader='true' :allowFiltering='true' gridLines='Both' :load='loadEco' :excelHeaderQueryCellInfo='excelHeader'
      :queryCellInfo='customiseCell' :allowExcelExport='true' :allowPdfExport='true' :allowGrouping='true'
      :allowTextWrap='true' :allowResizing='true' :frozenColumns='columns' :frozenRows="rows"  >

    >
    <e-columns>
        <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false>
        </e-column>
        <e-column field='summary_pos' :headerText="translate('plannings_plbs_summary_pos')" width=220>
        </e-column>
        <e-column field='position' :headerText="translate('plannings_plbs_position')" width=220>
        </e-column>
        <e-column textAlign='center' :headerText="translate('Periods')" :columns='periodsColumnsEco'>
        </e-column>
      </e-columns>
    </ejs-grid>
    <ejs-grid
      :dataSource='itemsOriginal'
      v-if="dataLoaded" v-show="false"
      :selectionSettings='selectionOptions' :pageSettings='pageSettings' :sortSettings='sortOptions' :searchSettings='searchOptions'
      :allowPaging='false' :withHeader='true' :allowFiltering='true' gridLines='Both' :load='load1' :excelHeaderQueryCellInfo='excelHeader'
      :queryCellInfo='customiseCell' :allowExcelExport='true' :allowPdfExport='true' :allowGrouping='true'
      :allowTextWrap='true' :allowResizing='true' :frozenColumns='columns' :frozenRows="rows" >
    >
      <e-columns>
        <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false>
        </e-column>
        <e-column field='summary_pos' :headerText="translate('plannings_plbs_summary_pos')" width=220>
        </e-column>
        <e-column field='position' :headerText="translate('plannings_plbs_position')" width=220>
        </e-column>
        <e-column textAlign='center' :headerText="translate('Periods')" :columns='periodsColumns'>
        </e-column>
      </e-columns>
    </ejs-grid>
    <v-btn color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="toolbarClick()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import Breadcrumbs from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import {
  GridPlugin, Page, Sort, Resize, CommandColumn, Search, Freeze, ExcelExport, Toolbar,
} from '@syncfusion/ej2-vue-grids';
import api from '../api/lbt-api';

const {
  getSummaryRISKForPlanning,
  getSummaryRISKECOForPlanning,
} = api;

Vue.use(GridPlugin);

export default {
  name: 'SummaryRISK',
  components: {
    Breadcrumbs,
    Snackbar,
  },
  data() {
    return {
      notificationText: '',
      loader: false,
      dataLoaded: false,
      dataLoadedEco: false,
      notificationType: null,
      items: [],
      itemsEco: [],
      itemsOriginal: [],
      itemsRISKECO: [],
      expanded: [],
      periods: [],
      periodsEco: [],
      columns: 4,
      rows: 0,
      toolbar: ['ExcelExport', 'PdfExport', 'CsvExport'],
      periodsColumns: [],
      periodsColumnsEco: [],
      sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      searchOptions: { fields: ['leasing_type_id'], operator: 'contains', ignoreCase: true },
      pageSettings: { pageSizes: true, pageSize: 100 },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'SUMMARY RISK',
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  provide: {
    grid: [Page, Sort, Resize, Search, CommandColumn, ExcelExport, Toolbar, Freeze],
  },
  async created() {
    this.headers = [

      {
        text: this.translate('plannings_plbs_summary_pos'),
        align: 'left',
        sortable: false,
        colspan: 10,
      },

      {
        text: this.translate('plannings_plbs_position'),
        align: 'left',
        sortable: false,
        width: 350,
      },
    ];
    this.loader = true;
    try {
      const planning = await getSummaryRISKForPlanning(this.$route.params.planningId);
      this.periods = planning.periods;
      // eslint-disable-next-line arrow-body-style
      const items = planning.rows;
      this.itemsOriginal = JSON.parse(JSON.stringify(items));
      /* const percRows = ['NPL ratio (NPL Volume/LtC gross)', 'NPL ratio adjusted (NPL Volume EcoV/TE)',
      'Risk provision ratio (Risk provision/IFRS exposure)',
      'Risk provision ratio adjusted (Risk provision/TE)',
        'Risk cost ratio (Risk cost/LTC gross)', 'Risk cost ratio adjusted (Risk cost/TE)', 'Risk Earning Ratio (Risk cost/NII IFRS)',
         'NPL Coverage Ratio (Provisions /NPL Volume)', 'Total  NPL Coverage Ratio ((Prov.+NPL Coll. Val.)/NPL Vol.)',
         'NEW Risk Costs Ratio (Risk cost/LTC average)', 'No. of contracts NPL/Total activated contract',
        'NEW Risk Costs Ratio adjusted (Risk cost/TE average)', 'Risk Earning Ratio adjusted (Risk cost/NII EV)',
        'NPL Coverage Ratio (EV Provisions/EV NPL Volume)', 'Total Coverage Ratio adjusted ((Prov.+NPL Coll. Val.)/NPL Vol.)',
      ];

      const tdecRows = ['IFRS exposure (in 000EUR)', 'NII IFRS (in 000EUR)', 'Loan to customer Gross (in 000EUR)',
      'Collateral value', 'Average of Loans and receivables to customers Gross (in 000EUR)',
       'NPL volume (in 000 EUR)', 'Risk provisions (in 000 EUR)', 'Risk cost (in 000 EUR)'];
      for (let i = 0; i < items.length; i += 1) {
        if (percRows.includes(items[i].summary_pos)) {
          for (let j = 0; j < items[i].values.length; j += 1) {
            items[i].values[j] = `${(items[i].values[j] * 100).toFixed(2)}%`;
          }
        }
        if (tdecRows.includes(items[i].summary_pos)) {
          for (let j = 0; j < items[i].values.length; j += 1) {
            items[i].values[j] = `${(items[i].values[j]).toFixed(2)}`;
            const parts = items[i].values[j].split('.');
            const reverseString = this.reverse(parts[0]);
            const parts2 = reverseString.match(/.{1,3}/g);
            let newValue = parts2.join(',');
            newValue = this.reverse(newValue);
            if (newValue.substring(0, 2) === '-,') {
              newValue = `-${newValue.substring(2, 100000)}`;
            }
            items[i].values[j] = `${newValue}.${parts[1]}`;
          }
        }
      } */
      this.items = items;
      this.prepareHeaders();
      this.dataLoaded = true;
      const planningEco = await getSummaryRISKECOForPlanning(this.$route.params.planningId);
      this.periodsEco = planningEco.periods;
      // eslint-disable-next-line arrow-body-style
      const itemsEco = planningEco.rows;
      /* const percRowsEco = ['NPL ratio', 'NPL ratio adjusted', 'Risk provision ratio', 'Risk provision ratio adjusted',
        'Risk cost ratio adjusted', 'NEW Risk Costs Ratio adjusted', 'Risk Earning Ratio adjusted', 'NPL Coverage Ratio', 'Total Coverage Ratio adjusted', 'No. of contracts NPL/Total activated contract'];

      const tdecRowsEco = ['Total exposure (in 000 EUR)', 'Average of Total exposure (in 000EUR)', 'Collateral Value', 'NII EV (in 000EUR)', 'NPL volume EV (in 000 EUR)', 'Risk provisions (in 000 EUR)', 'Risk cost (in 000 EUR)'];
      for (let i = 0; i < itemsEco.length; i += 1) {
        if (percRowsEco.includes(itemsEco[i].summary_pos)) {
          for (let j = 0; j < itemsEco[i].values.length; j += 1) {
            itemsEco[i].values[j] = `${(itemsEco[i].values[j] * 100).toFixed(2)}%`;
          }
        }
        if (tdecRowsEco.includes(itemsEco[i].summary_pos)) {
          for (let j = 0; j < itemsEco[i].values.length; j += 1) {
            itemsEco[i].values[j] = `${(itemsEco[i].values[j]).toFixed(2)}`;
            const partsEco = itemsEco[i].values[j].split('.');
            const reverseStringEco = this.reverse(partsEco[0]);
            const parts2Eco = reverseStringEco.match(/.{1,3}/g);
            let newValueEco = parts2Eco.join(',');
            newValueEco = this.reverse(newValueEco);
            if (newValueEco.substring(0, 2) === '-,') {
              newValueEco = `-${newValueEco.substring(2, 100000)}`;
            }
            itemsEco[i].values[j] = `${newValueEco}.${partsEco[1]}`;
          }
        }
      } */
      this.itemsEco = itemsEco;
      this.prepareHeadersEco();
      this.dataLoadedEco = true;
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  methods: {
    toggleExpandedItem(item) {
      // eslint-disable-next-line
      item.isExpanded = !item.isExpanded;
      this.expanded.push(item);
    },
    reverse(y) {
      if (y.length === 1 || y.length === 0) {
        return y;
      }
      return y.split('')[y.length - 1] + this.reverse(y.slice(0, y.length - 1));
    },
    excelDownloadClick() {
      const appendExcelExportProperties = {
        multipleExport: { type: 'AppendToSheet', blankRows: 5 },
        fileName: `Summary IFRS-ECO- ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      const firstGridExport = this.$refs.grid.excelExport(appendExcelExportProperties, true);
      firstGridExport.then((fData) => {
        this.$refs.gridEco.excelExport(appendExcelExportProperties, false, fData);
      });
    },
    excelQueryCellInfo(args) {
      const newValue = parseFloat(args.value); // Parse value to number
      if (!Number.isNaN(newValue)) {
        // Assign new value to args.value using Object.assign
        Object.assign(args, { value: newValue });
      }
    },
    customiseCell(queryCellInfo) {
      const { cell } = queryCellInfo;
      if (cell.textContent === 'NaN') {
        cell.classList.add('error');
      }
    },
    excelHeader(args) {
      const { cell } = args;
      if (cell.index > 2) {
        cell.value = this.periods[cell.value];
      }
    },
    lookupHeader(idx) {
      return this.periods[Number(idx)];
    },
    toolbarClick() {
      const grid = document.getElementsByClassName('e-grid')[1].ej2_instances[0];
      grid.excelExport();
    },
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.copy(true);
    },
    loadEco() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.copy(true);
    },
    load1() {
      const grid = document.getElementsByClassName('e-grid')[1].ej2_instances[0];
      grid.copy(true);
    },
    prepareHeaders() {
      const item = this.items[0];
      for (let j = 0; j < item.values.length; j += 1) {
        this.periodsColumns.push({
          field: `values.${j}`,
          headerText: j,
          headerValueAccessor: this.lookupHeader,
          valueAccessor: this.valueAccessor,
          width: 70,
          format: 'N2',
          textAlign: 'right',
        });
      }
    },
    prepareHeadersEco() {
      const itemEco = this.itemsEco[0];
      for (let j = 0; j < itemEco.values.length; j += 1) {
        this.periodsColumnsEco.push({
          field: `values.${j}`,
          headerText: j,
          headerValueAccessor: this.lookupHeader,
          valueAccessor: this.valueAccessor,
          width: 70,
          format: 'N2',
          textAlign: 'right',
        });
      }
    },
  },
};
</script>


<style>
  @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

  .container {
    padding-bottom: 5rem !important;
  }

  .e-grid.e-default .e-gridheader th.e-firstcell {
    border-left: none;
  }

  .e-dlg-container.e-dlg-center-center,
  .e-dlg-container.e-dlg-center-center {
    display: none;
  }

  .e-grid .e-headercell.highlight-cell, .highlight-cell {
    background: #eeeeee;
  }

  .v-icon.v-icon.delete-action {
    font-size: 16px;
  }
</style>
